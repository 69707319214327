<script setup lang="ts" generic="T extends ISidebarSection = ISidebarSection">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { Sidebar, type ISidebarSection } from '@ui/components/Sidebar'
import SidebarSkeleton from './TheLeaseSidebarSkeleton.vue'
import type { ITheLeaseSidebarAction } from './types'

const props = withDefaults(
  defineProps<{
    sections: T[]
    actions?: ITheLeaseSidebarAction[]
    search?: string
    loading?: boolean
    fullHeight?: boolean
  }>(),
  {
    sections: () => [],
    actions: () => [],
    search: undefined,
    loading: false,
    fullHeight: true,
  },
)

const emit = defineEmits<{
  'update:search': [value: string]
  click: [id: string]
  prefetch: [id: string]
}>()

defineSlots<{
  header: () => any
  default: (scope: { sections: T[] }) => any
  'section-title': (scope: { section: T }) => any
  'item-suffix': (scope: { item: T['items'][number] }) => any
}>()

const hasActions = computed(() => props.actions.length > 0)
const route = useRoute()
const currentSection = computed(() => route.query.section)
</script>

<template>
  <SidebarSkeleton
    v-if="loading"
    class="absolute inset-0"
    :class="fullHeight ? 'h-full' : 'h-[calc(100%-72px)]'"
  />
  <Sidebar
    v-else
    class="absolute inset-0"
    :class="fullHeight ? 'h-full' : 'h-[calc(100%-72px)]'"
  >
    <template #header>
      <div
        v-auto-animate
        class="w-full justify-between space-y-4 border-b border-gray-700 pb-4 pl-3 pr-1"
      >
        <slot name="header">
          <div class="flex w-full items-center justify-between">
            <FormKit
              type="search"
              placeholder="Search for fields"
              outer-class="flex-auto flex w-full"
              wrapper-class="w-full flex"
              :model-value="search"
              :delay="150"
              @update:model-value="emit('update:search', $event as string)"
            >
              <template #prefix>
                <Icon name="search" class="text-gray-350 ml-1" filled />
              </template>
            </FormKit>

            <Menu
              v-if="hasActions"
              ref="popover"
              v-slot="{ open }"
              as="div"
              class="relative z-20"
            >
              <MenuButton
                class="hover:border-primary hover:bg-primary focus-visible:bg-primary mb-1 rounded-md border border-transparent px-0.5 py-2 hover:text-black focus-visible:text-black focus-visible:outline-none"
                :class="{
                  'bg-primary text-black': open,
                }"
              >
                <Icon name="kebab-menu" />
              </MenuButton>

              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="bg-gray-650 absolute right-0 z-10 mt-2 flex origin-top-right flex-col divide-y divide-gray-600 rounded-md border border-gray-600 shadow-2xl ring-1 ring-black ring-opacity-5 focus-visible:outline-none"
                >
                  <MenuItem
                    v-for="(action, idx) in actions"
                    :key="idx"
                    v-slot="{ active }"
                  >
                    <Button
                      :color="active ? 'secondary' : 'transparentSecondary'"
                      class="justify-start whitespace-nowrap rounded-none"
                      :icon="action.icon"
                      @click.stop="action.click()"
                    >
                      {{ action.title }}
                    </Button>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </slot>
      </div>
    </template>

    <slot v-bind="{ sections }">
      <SidebarSection
        v-for="section in sections"
        :id="'section-' + section.id"
        :key="'section-' + section.id"
        :title="section.title"
        :collapsable="true"
        section-class="mt-1"
        :collapsed="false"
      >
        <template v-if="$slots['section-title']" #title>
          <slot name="section-title" v-bind="{ section }" />
        </template>

        <SidebarItem
          v-for="item in section.items"
          :id="'item-' + item.id"
          :key="'item-' + item.id"
          :icon="item.icon"
          :to="item.to"
          :active="currentSection === item.id"
          @mouseover="emit('prefetch', item.id)"
          @focus="emit('prefetch', item.id)"
          @click="emit('click', item.id)"
        >
          {{ item.title }}

          <template v-if="$slots['item-suffix']" #suffix>
            <slot name="item-suffix" v-bind="{ item }" />
          </template>
        </SidebarItem>
      </SidebarSection>
    </slot>
  </Sidebar>
</template>
